import React, {useState} from "react";
import NextButton from "../elements/buttons/NextButton";
import Pagination from "../elements/Pagination";
import TextInputWithImage from "../elements/textinput/TextInputWithImage";
import Extras from "./Extras";
import BackButton from "../elements/buttons/BackButton";
import HorizontalAds from "../../../../../../Ads/HorizontalAds";
import SquareAds from "../../../../../../Ads/SquareAds";

export default function Donate({back}) {
    const [isVisible, setIsVisible] = useState(false);
    const [buymeacoffee, setBuymeacoffee] = useState([]);
    const [paypal, setPaypal] = useState([]);
    const [patreon, setPatreon] = useState([]);
    const [kofi, setKofi] = useState([]);

    function onNext() {
        donate = ``;

        if (document.getElementById("buymeacoffee").value != "") {
            setBuymeacoffee(document.getElementById("buymeacoffee").value);
            donate =
                donate +
                `[![BuyMeACoffee](https://img.shields.io/badge/Buy%20Me%20a%20Coffee-ffdd00?style=for-the-badge&logo=buy-me-a-coffee&logoColor=black)](https://buymeacoffee.com/${
                    document.getElementById("buymeacoffee").value
                }) `;
        }
        if (document.getElementById("paypal").value != "") {
            setPaypal(document.getElementById("paypal").value);
            donate =
                donate +
                `[![PayPal](https://img.shields.io/badge/PayPal-00457C?style=for-the-badge&logo=paypal&logoColor=white)](https://paypal.me/${
                    document.getElementById("paypal").value
                }) `;
        }
        if (document.getElementById("patreon").value != "") {
            setPatreon(document.getElementById("patreon").value);
            donate =
                donate +
                `[![Patreon](https://img.shields.io/badge/Patreon-F96854?style=for-the-badge&logo=patreon&logoColor=white)](https://patreon.com/${
                    document.getElementById("patreon").value
                }) `;
        }
        if (document.getElementById("kofi").value != "") {
            setKofi(document.getElementById("kofi").value);
            donate =
                donate +
                `[![Ko-Fi](https://img.shields.io/badge/Ko--fi-F16061?style=for-the-badge&logo=ko-fi&logoColor=white)](https://ko-fi.com/${
                    document.getElementById("kofi").value
                }) `;
        }
        setIsVisible(true);
    }

    return (
        <>
            {isVisible ? (
                <Extras back={() => setIsVisible(false)}/>
            ) : (
                <>
                    <BackButton
                        onClick={back}
                        to="Go Back"
                    >
                    </BackButton>
                    <div className="flex flex-col items-center fade-on-appear">
                        <p className="w-full text-center text-3xl my-10">
                            Let People Help You via Donations
                        </p>
                        <div className="flex flex-col md:flex-row w-full">
                            <div className="flex w-full md:w-6/12 justify-center items-center">
                                <img
                                    src="/images/icons/tools/generator%20tools/github%20readme%20generator/donate.svg"
                                    alt=""
                                    className="w-8/12 aspect-square select-none pointer-events-none -rotate-3"
                                    draggable="false"
                                />
                            </div>
                            <div className="flex flex-col w-full md:w-6/12">
                                <p className="text-4xl md:text-5xl font-semibold text-green-300">
                                    Donate :
                                </p>
                                <TextInputWithImage
                                    id="buymeacoffee"
                                    value={buymeacoffee}
                                    placeholder="Buy Me a Coffee Username"
                                    imgUrl="/images/icons/tools/generator%20tools/github%20readme%20generator/bmc.svg"
                                />
                                <TextInputWithImage
                                    id="paypal"
                                    value={paypal}
                                    placeholder="PayPal.Me Username"
                                    imgUrl="/images/icons/tools/generator%20tools/github%20readme%20generator/paypal.svg"
                                />
                                <TextInputWithImage
                                    id="patreon"
                                    value={patreon}
                                    placeholder="Patreon Username"
                                    imgUrl="/images/icons/tools/generator%20tools/github%20readme%20generator/patreon.svg"
                                />
                                <TextInputWithImage
                                    id="kofi"
                                    value={kofi}
                                    placeholder="Ko-Fi Username"
                                    imgUrl="/images/icons/tools/generator%20tools/github%20readme%20generator/kofi.svg"
                                />
                                <div
                                    className="flex flex-col items-end  w-full md:w-10/12 justify-center md:justify-end mt-4">
                                    <NextButton onClick={() => onNext()}/>
                                    <SquareAds/>
                                </div>
                            </div>
                        </div>
                        <Pagination val={5}/>
                    </div>

                </>
            )}
        </>
    );
}
export var donate = ``;
