import FormButton from "./FormButton"

const WorkExperience = ({ resumeData, handleWorkExperience, addWorkExperience, removeWorkExperience }) => {
    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-white">Work Experience</h2>
        {resumeData.workExperience.map((workExperience, index) => (
          <div key={index} className="grid grid-1 gap-2 mb-4">
            <input
              type="text"
              placeholder="Company"
              name="company"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
              value={workExperience.company}
              onChange={(e) => handleWorkExperience(e, index)} />
            <input
              type="text"
              placeholder="Job Title"
              name="position"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
              value={workExperience.position}
              onChange={(e) => handleWorkExperience(e, index)} />
            <textarea
              type="text"
              placeholder="Description"
              name="description"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400 h-32"
              value={workExperience.description}
              maxLength="250"
              onChange={(e) => handleWorkExperience(e, index)} />
            <textarea
              type="text"
              placeholder="Key Achievements"
              name="keyAchievements"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400 h-40"
              value={workExperience.keyAchievements}
              onChange={(e) => handleWorkExperience(e, index)} />
            <div className="flex flex-wrap gap-2">
              <input
                type="date"
                placeholder="Start Year"
                name="startYear"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={workExperience.startYear}
                onChange={(e) => handleWorkExperience(e, index)} />
              <input
                type="date"
                placeholder="End Year"
                name="endYear"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={workExperience.endYear}
                onChange={(e) => handleWorkExperience(e, index)} />
            </div>
          </div>
        ))}
        <FormButton size={resumeData.workExperience.length} add={addWorkExperience} remove={removeWorkExperience} />
      </div>
    )
  }

  export default WorkExperience