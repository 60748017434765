const Summary = ({ resumeData, handleChange }) => {
    return (
      <div className="flex flex-col gap-2 my-4">
        <h2 className="text-white">Summary</h2>
        <div className="grid-4">
          <textarea
            placeholder="Summary"
            name="summary"
            className="w-full other-input h-40 peer h-10 w-full rounded-md bg-gray-50 p-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
            value={resumeData.summary}
            onChange={handleChange} maxLength="250" />
        </div>
      </div>
    )
  }

    export default Summary;