import React, {useState} from 'react';
import HorizontalAds from "../../../../Ads/HorizontalAds";
import SquareAds from "../../../../Ads/SquareAds";
import {Helmet} from "react-helmet";

function PasswordGenerator() {
    const [length, setLength] = useState(12);
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [useUppercase, setUseUppercase] = useState(true);
    const [useLowercase, setUseLowercase] = useState(true);
    const [useNumbers, setUseNumbers] = useState(true);
    const [useSymbols, setUseSymbols] = useState(true);


    function handleChange(event) {
        setLength(event.target.value);
    }

    function generatePassword() {
        const length = parseInt(document.getElementById("length").value);
        const useUppercase = document.getElementById("uppercase").checked;
        const useLowercase = document.getElementById("lowercase").checked;
        const useNumbers = document.getElementById("numbers").checked;
        const useSymbols = document.getElementById("symbols").checked;

        let charset = "";
        if (useUppercase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        if (useLowercase) charset += "abcdefghijklmnopqrstuvwxyz";
        if (useNumbers) charset += "0123456789";
        if (useSymbols) charset += "!@#$%^&*";

        let password = "";
        for (let i = 0; i < length; i++) {
            password += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        setPassword(password);
    }

    function copyPassword() {
        if (password) {
            navigator.clipboard.writeText(password);
            setShowModal(true);

            setTimeout(() => {
                setShowModal(false);
            }, 1000);
        }
    }

    return (
        <div>
            <Helmet>
                <title>Password Generator</title>
                <meta
                    name="descriptions"
                    content="A strong password is essential to protect your online accounts from hackers and identity theft. With a password generator tool, you can create unique, complex passwords that are virtually impossible to guess. Discover the top password generator tools available, and learn how you can easily generate secure passwords for all your online accounts. With features such as password length, character types, and randomization, a password generator can help you stay safe and secure online. Don't risk your online security with weak passwords - try a password generator today and protect your digital identity."
                />
                <meta
                    name="keywords"
                    content="Password generator, Password manager, Password strength, Password security, Random passwords, Strong passwords, Complex passwords, Unique passwords, Password length, Character types, Password entropy, Password hashing, Password encryption, Online security, Identity theft, Cybersecurity, Data protection, Authentication, Multi-factor authentication, Two-factor authentication, Password policies, Password best practices, Password tips"/>
            </Helmet>
            <div className="md:flex items-center justify-center">
                <div className="flex justify-center mx-auto">
                    <SquareAds/>
                </div>
                <div>
                    <h1 className="text-center mt-8 font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 ml-3 my-6">
                        Random Password Generator
                    </h1>
                    <div className="bg-gray-700 p-8 mx-auto md:m-20 rounded-xl text-white w-96">
                        <h2 className="text-center font-extrabold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mb-6">Password
                            Generator</h2>
                        <div className='my-4 flex'>
                            <div className="w-full flex">
                                <div id='password'
                                     className="w-full text-gray-700 bg-gray-200 rounded-md pt-1">{password}</div>
                            </div>
                            <button
                                className='btn btn-sm mx-2 transition h-fit duration-1000 text-white rounded bg-gradient-to-tl from-blue-700 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-700 rounded-lg'
                                id='clipboard' title='Copy' onClick={copyPassword}>
                                <i className="fa-regular fa-paste"></i>
                            </button>
                        </div>
                        <div className={`modal ${showModal ? 'show' : ''}`}>
                            <div className='modal-content'>
                                Password copied!
                            </div>
                        </div>
                        <div className='options m-4'>
                            <div className='option my-4 text-start'>
                                <label className="pr-3">Length</label>
                                <input className="bg-gray-200 text-gray-700 rounded-md text-center" type='number'
                                       id='length'
                                       min='4' max='20' value={length} onChange={handleChange}/>
                            </div>
                            <div className='option my-4 text-start'>
                                <label className="pr-3">A-Z</label>
                                <input type='checkbox' className="h-4 w-4 border-gray-100 cursor-pointer rounded"
                                       id='uppercase' checked={useUppercase}
                                       onChange={(e) => setUseUppercase(e.target.checked)}/>
                            </div>
                            <div className='option my-4 text-start'>
                                <label className="pr-3">a-z</label>
                                <input type='checkbox' className="h-4 w-4 border-gray-100 cursor-pointer rounded"
                                       id='lowercase' checked={useLowercase}
                                       onChange={(e) => setUseLowercase(e.target.checked)}/>
                            </div>
                            <div className='option my-4 text-start'>
                                <label className="pr-3">0-9</label>
                                <input type='checkbox' className="h-4 w-4 border-gray-100 cursor-pointer rounded"
                                       id='numbers' checked={useNumbers}
                                       onChange={(e) => setUseNumbers(e.target.checked)}/>
                            </div>
                            <div className='option my-4 text-start'>
                                <label className="pr-3">!@#$%^&*</label>
                                <input type='checkbox' className="h-4 w-4 border-gray-100 cursor-pointer rounded"
                                       id='symbols' checked={useSymbols}
                                       onChange={(e) => setUseSymbols(e.target.checked)}/>
                            </div>
                        </div>
                        <button
                            className='transition h-fit px-4 duration-1000 text-white px-8 py-1 my-2 rounded bg-gradient-to-tl from-blue-700 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-700 rounded-lg'
                            id='generate' onClick={generatePassword}>
                            Generate Password
                        </button>
                    </div>
                </div>
                <div className="flex justify-center mx-auto">
                    <SquareAds/>
                </div>
            </div>

            <HorizontalAds/>
        </div>
    );
}

export default PasswordGenerator;