import React from 'react';
import logo from "../images/logo/logo.png";

function Nav() {
    return (
        <div className="mb-20 exclude-print">
            <nav className="p-2 mt-0 fixed w-full z-10 top-0 bg-base-100">
                <div className="navbar justify-between md:justify-evenly">
                    <div className="flex">
                        <div className="dropdown">
                            <label tabIndex="0" className="btn btn-ghost lg:hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M4 6h16M4 12h8m-8 6h16"/>
                                </svg>
                            </label>
                            <ul tabIndex="0"
                                className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                                <li><a className="active:bg-blue-500" href="/">Home</a></li>
                                <li tabIndex="0">
                                    <a href="/" className="justify-between active:bg-blue-500">
                                        Tools
                                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24">
                                            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
                                        </svg>
                                    </a>
                                    <ul className="p-2 bg-white">
                                        <li><a href="/" className="active:bg-blue-500">Text Tools</a></li>
                                        <li><a href="/" className="active:bg-blue-500">Graphic Tools</a></li>
                                    </ul>
                                </li>
                                <li><a href="https://www.techielash.me" className="active:bg-blue-500">Blog</a></li>
                                <li><a href="/about-us" className="active:bg-blue-500">About Us</a></li>
                                <li><a href="/contact-us" className="active:bg-blue-500">Contact Us</a></li>
                            </ul>
                        </div>
                        <a href="/" className="mx-1 normal-case text-xl">
                            <div className="flex justify-center items-center">
                                <img src={logo} alt="" className="w-[110px] md:w-[200px] h-[25px] md:h-[50px]"/>
                                <h1 className="text-center font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-lg md:text-3xl mt-2 ml-3">Tools</h1>
                            </div>
                        </a>
                    </div>
                    <div className="hidden lg:flex">
                        <ul className="menu menu-horizontal px-1">
                            <li><a href="/" className="active:bg-blue-500">Home</a></li>
                            <li tabIndex="0">
                                <a href="/" className="active:bg-blue-500">
                                    Tools
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20"
                                         height="20"
                                         viewBox="0 0 24 24">
                                        <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                    </svg>
                                </a>
                                <ul className="p-2 bg-white">
                                    <li><a href="/" className="active:bg-blue-500">Text Tools</a></li>
                                    <li><a href="/" className="active:bg-blue-500">Graphic Tools</a></li>
                                </ul>
                            </li>
                            <li><a href="https://www.techielash.me" className="active:bg-blue-500">Blog</a></li>
                            <li><a href="/about-us" className="active:bg-blue-500">About Us</a></li>
                            <li><a href="/contact-us" className="active:bg-blue-500">Contact Us</a></li>
                        </ul>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/techielash" className="mx-1 md:mx-2">
                            <i className="fa-brands fa-facebook text-blue-700 hover:text-blue-800 text-xl md:text-2xl"></i>
                        </a>
                        <a href="https://www.techielash.me/" className="mx-1 md:mx-2">
                            <i className="fa-duotone fa-blog text-orange-400 hover:text-orange-500 text-xl md:text-2xl"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/techie-lash/" className="mx-1 md:mx-2">
                            <i className="fa-brands fa-linkedin text-blue-800 hover:text-blue-900 text-xl md:text-2xl"></i>
                        </a>
                        <a href="https://wa.me/0766196446" className="mx-1 md:mx-2">
                            <i className="fa-brands fa-whatsapp text-green-500 hover:text-green-600 text-xl md:text-2xl"></i>
                        </a>
                        <a href="https://www.youtube.com/@devlashen" className="mx-1 md:mx-2">
                            <i className="fa-brands fa-youtube  text-red-500 hover:text-red-600 text-xl md:text-2xl"></i>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Nav;