import FormButton from "./FormButton";

const Education = ({ resumeData, handleEducation, addEducation, removeEducation }) => {
    return (
      <div className="flex flex-col gap-2 my-4">
        <h2 className="text-white">Education</h2>
        {resumeData.education.map((education, index) => (
          <div key={index} className="grid grid-1 gap-2">
            <input
              type="text"
              placeholder="School"
              name="school"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
              value={education.school}
              onChange={(e) => handleEducation(e, index)} />
            <input
              type="text"
              placeholder="Degree"
              name="degree"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
              value={education.degree}
              onChange={(e) => handleEducation(e, index)} />
            <div className="flex flex-wrap gap-2">
              <input
                type="date"
                placeholder="Start Year"
                name="startYear"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={education.startYear}
                onChange={(e) => handleEducation(e, index)} />
              <input
                type="date"
                placeholder="End Year"
                name="endYear"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={education.endYear}
                onChange={(e) => handleEducation(e, index)} />
            </div>
          </div>
        ))}
        <FormButton size={resumeData.education.length} add={addEducation} remove={removeEducation} />
      </div>
    )
  }

export default Education;