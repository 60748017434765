import Icon from "../../../../../Components/Icon";

const TechSkills = ({title, skills}) => {
    return (skills.length > 0 && <div>
        <h2 className="w-full flex mb-1 border-b-2 border-gray-300">{title}</h2>
        <p className="grid grid-cols-2 text-sm">
            {skills.map((skill, key) => <>
                <div key={key} className="bg-blue-500 text-white m-1 rounded text-center">{skill}</div>
            </>)}
        </p>
    </div>);
};

export default TechSkills;