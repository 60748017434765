import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import HorizontalAds from "../../Ads/HorizontalAds";
import VerticalAds from "../../Ads/VerticalAds";
import {Helmet} from "react-helmet";

const ContactUs = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ek1tidm', 'template_mor9m5z', form.current, 'obJN8CftTJ-gTIJt4')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    };

    return (<>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <div>
                <div>
                    <div>
                        <div className="mx-auto flex flex-col justify-center">
                            <div className="max-w-screen-md mx-auto p-5">
                                <div className="text-center mb-16">
                                    <p className="mt-4 text-sm leading-7 text-gray-500 font-regular uppercase">
                                        Contact
                                    </p>
                                    <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
                                        Get In <span
                                        className="text-center font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 my-6">Touch</span>
                                    </h3>
                                </div>

                                <form ref={form} onSubmit={sendEmail} className="w-full">
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="block uppercase text-start tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-first-name">
                                                First Name
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="grid-first-name" type="text" placeholder="Jane" name="first_name"
                                                required>
                                            </input>
                                            {/*<p className="text tools-red-500 text tools-xs italic">Please fill out this field.</p>*/}
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label
                                                className="block uppercase text-start tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-last-name">
                                                Last Name
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="grid-last-name" type="text" placeholder="Doe" name="second_name"
                                                required>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label
                                                className="block uppercase text-start tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                Email Address
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="grid-email" type="email" placeholder="********@*****.**"
                                                name="email" required>
                                            </input>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label
                                                className="block uppercase text-start tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                Your Message
                                            </label>
                                            <textarea rows="10" name="message"
                                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                      required>

      </textarea>
                                        </div>
                                        <div className="flex justify-center w-full px-3">
                                            <button
                                                className="shadow bg-indigo-600 hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
                                                type="submit">
                                                Send Message
                                            </button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;