import React, {useState} from "react";
import {TextLoop} from "react-text-loop-next";
import { TypeAnimation } from 'react-type-animation';

export default function AnimatedText() {
    const [textColor, setTextColor] = useState('rose');

    return (
        <div className="hidden md:block sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-medium overflow-y-hidden">
            Create{" "}
            <span className={'text tools-'+ textColor + '-200 underline underline-offset-4 decoration-dashed'}>
                <TypeAnimation
                    sequence={[
                        'Beautiful',
                        2000,
                        () => setTextColor('cyan'),
                        'Modern',
                        2000,
                        () => setTextColor('amber'),
                        'Interactive',
                        2000,
                        () => setTextColor('green'),
                        'Perfect',
                        2000,
                        () => setTextColor('rose'),
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                />
            </span>
            <TextLoop>
        {/*<span className="text tools-rose-200 underline underline-offset-4 decoration-dashed">*/}
        {/*  Beautiful*/}
        {/*</span>*/}
        {/*        <span className="text tools-cyan-200 underline underline-offset-4 decoration-dashed">*/}
        {/*  Modern*/}
        {/*</span>*/}
        {/*        <span >*/}
        {/*  Interactive*/}
        {/*</span>*/}
        {/*        <span className="text tools-green-200 underline underline-offset-4 decoration-dashed">*/}
        {/*  Perfect*/}
        {/*</span>*/}
            </TextLoop>{" "}
            Profile
        </div>
    );
}
