import React from "react";

export default function CheckBox({onClick, id, title, defaultChecked, checked}) {
    return (<p className="flex w-max items-center my-2">
            <input
                type="checkbox"
                name=""
                onClick={() => onClick()}
                id={id}
                defaultChecked={checked || false}
                className="w-4 h-4 mr-2 outline-none"
            />
            {title}
        </p>);
}
