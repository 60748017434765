import JoditEditor from "jodit-react";
import React, {useRef, useState} from "react";
import MyStatefulEditor from "./rte_test";
import './style.css';
import VerticalAds from "../../../../Ads/VerticalAds";
import HorizontalAds from "../../../../Ads/HorizontalAds";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Helmet} from "react-helmet";

export default function TextEditor() {
    const [copy, setCopy] = useState(false)
    const [val, setVal] = useState("");
    const [message, setMessage] = useState("");
    const onChange = (value) => {
        console.log(value);
        setVal(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(message);
    };

    return (
        <div>
            <Helmet>
                <title>Text Editor</title>
                <meta
                    name="descriptions"
                    content="A text editor is a powerful tool that allows you to create, edit and manage text-based documents with ease. From writing code to drafting important emails, a text editor can help you streamline your workflow and stay organized. Discover the top text editor tools available, and learn how you can boost your productivity and create professional-looking documents in no time. With features such as syntax highlighting, auto-completion, and version control, a text editor is an essential tool for anyone who works with text. Try a text editor today and see the difference it can make!"
                />
                <meta
                    name="keywords"
                    content="Text editor, Code editor, Word processor, Syntax highlighting, Auto-completion, Version control, Plain text, Rich text, Markdown, HTML editor, CSS editor, JavaScript editor, PHP editor, Python editor, Ruby editor, Command line, Keyboard shortcuts, Customization, Productivity, Efficiency, Organization, Professional documents, Editing tools, Writing tools, Development tools"/>
            </Helmet>
            <div className="md:flex mx-auto text-start">
                <div className="m-4">
                    <VerticalAds/>
                </div>
                <div className="w-full">
                    <h1 className="text-center mt-8 font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 ml-3 my-6">
                        Text Editor
                    </h1>
                    <div className="mt-6">
                        {/*<MyStatefulEditor markup="" onChange={() => setCopy(false)}/>*/}
                        <RichTextEditor getValue={(value) => [setVal(value), setCopy(false)]}/>
                        {/*<p>{val}</p>*/}
                        {/*<hr/>*/}
                        {/*<div className="h-80" dangerouslySetInnerHTML={{__html: val}}/>*/}
                        {/*<form onSubmit={(e) => handleSubmit(e)}>*/}
                        {/*    <input*/}
                        {/*        value={message}*/}
                        {/*        onChange={(e) => setMessage(e.target.value)}*/}
                        {/*        required*/}
                        {/*        type="text tools"*/}
                        {/*        name={"message"}*/}
                        {/*        placeholder="Write a message"*/}
                        {/*    />*/}
                        {/*    <button type="submit">Add</button>*/}
                        {/*</form>*/}
                        <div className="flex justify-center">
                            <CopyToClipboard text={val}
                                             onCopy={() => setCopy(true)}>
                                <button
                                    className={`${copy ? 'copied' : 'copy'}` + 'transition h-fit px-4 duration-1000 text tools-white px-8 py-1 my-2 rounded bg-gradient-to-tl from-blue-700 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-700 rounded-lg'}>
                                    {copy ? 'Copied !' :
                                        'Copy as HTML'
                                    }
                                </button>
                            </CopyToClipboard>
                        </div>
                        <div className="m-4">
                            <HorizontalAds/>
                        </div>
                    </div>
                </div>

                <div className="m-4 hidden md:block">
                    <VerticalAds/>
                </div>
            </div>
        </div>
    );
}

const config = {
    buttons: ["bold", "italic", "link", "unlink", "underline", "source"]
};

export const RichTextEditor = ({initialValue, getValue}) => {
    const editor = useRef(null);

    return (
        <>
            <div>
                <JoditEditor
                    ref={editor}
                    value={initialValue}
                    // config={config}
                    tabIndex={1}
                    //   onBlur={(newContent) => getValue(newContent)}
                    onChange={(newContent) => {
                        getValue(newContent);
                        // console.log(newContent);
                    }}
                    // onChange={(event, editor) => {
                    //   // onChange(data);
                    // }}
                />
            </div>

        </>
    );
};
