import React from "react";

export default function Pagination({val}) {
    return (
        <div className="flex items-start w-full">
            <div className="left-2 px-6 py-2 bg-green-300 text-zinc-800 rounded-full font-semibold">
                {val} / 6
            </div>
        </div>
    );
}
