import React, {Component} from 'react';
import {Link, redirect} from 'react-router-dom';

class Icon extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="grid grid-cols-1 my-6 hover:font-bold text-white">
            <Link to={this.props.url}>
                <div className="bg-white shadow-lg rounded-full mx-3 md:mx-12 my-3 p-3 2xl:p-5 hover:bg-gray-50">
                    <div className="w-[56] h-[56] md:w-[70] md:h-[70]">
                        <img className="w-[56] h-[56] md:w-[70] md:h-[70] object-cover" src={this.props.img} alt={this.props.title}/>
                    </div>
                </div>
                <p className="w-full text-sm md:text-md truncate">{this.props.title}</p>
            </Link>
        </div>)
    }
}

export default Icon;
