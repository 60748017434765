import React, {useState} from "react";
import AnimatedText from "../elements/AnimatedText";
import Features from "../home-components/Features";
import GitHubAvailability from "../home-components/GitHubAvailability";
import ToastError from "../elements/toaster/ToastError";
import AboutMe from "./AboutMe";
import FAQ from "../home-components/FAQ";
import Credits from "../home-components/Credits";
import SocialLinks from "../home-components/SocialLinks";
import ScrollToTop from "../elements/ScrollToTop";
import {RIGHT_ARROW_SVG} from "../elements/SVG";
import BackButton from "../elements/buttons/BackButton";
import HorizontalAds from "../../../../../../Ads/HorizontalAds";
import VerticalAds from "../../../../../../Ads/VerticalAds";
import Donate from "./Donate";
import Extras from "./Extras";
import {Helmet} from "react-helmet";

export default function HomePage() {
    const [isVisible, setIsVisible] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [input, setInput] = useState("");

    function onNext() {
        if (input != "" && input.replace(/ /g, "") != "") {
            username = input;
            setIsVisible(true);
            topFunction();
        } else {
            invalidUsername();
        }
    }

// When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function invalidUsername() {
        if (alertVisible !== true) {
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 4400);
        }
    }

    return (<>
        <Helmet>
            <title>GitHub ReadMe Generator</title>
            <meta
                name="descriptions"
                content="Creating a great README file is essential for any software project, but it can be time-consuming and challenging to write from scratch. A GitHub README generator tool can help you easily create professional-looking README files for your repositories. Discover the top GitHub README generator tools available, and learn how you can save time and effort by using pre-designed templates and customizable elements. With features such as code snippets, images, and formatting options, a GitHub README generator can help you showcase your project and attract contributors. Try a GitHub README generator today and elevate your project's documentation to the next level!"
            />
            <meta
                name="keywords"
                content="GitHub README generator, README file, Software documentation, Project documentation, README template, Markdown, HTML, Formatting options, Code snippets, Images, Customizable elements, GitHub repository, Project management, Open-source, Collaboration, Project showcase, GitHub Pages, Readme.md, Code documentation, Software development, Web development, Productivity, Efficiency, Workflow optimization"/>
        </Helmet>
        <div className="bg-gradient-to-r from-blue-500 to-blue-800 text-blue-300 p-6 min-h-full">
            {isVisible ? (<AboutMe back={() => setIsVisible(false)}/>) : (<div className="scroll-smooth">
                <div
                    className="w-full flex flex-col md:flex-row  min-h-[90vh] items-center relative">
                    <VerticalAds/>
                    <div className="flex flex-col mt-12 w-full md:w-6/12 relative">
                        <p className="text-6xl md:text-7xl 2xl:text-8xl">
                            GitHub Readme.md Generator
                        </p>
                        {/* Text Input */}
                        <div className="flex flex-col sm:flex-row mt-8 md:my-16 2xl:my-20 items-center">
                            <form className="w-full" onSubmit={onNext}>
                                <input
                                    type="text"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    autoFocus={true}
                                    className="placeholder-white border-b-2 border-green-200 place-holder-text-white bg-transparent w-full sm:w-11/12 md:w-10/12 lg:w-8/12 text-xl sm:text-3xl md:text-xl lg:text-2xl 2xl:text-3xl outline-none focus:border-green-300 focus:border-b-4 inline"
                                    placeholder="Enter Your GitHub Username"
                                />
                                <button type="Submit">
                                    <RIGHT_ARROW_SVG/>
                                </button>
                            </form>
                        </div>
                        <AnimatedText/>
                    </div>
                    <div className="flex w-full mt-16 md:mt-0 md:w-6/12 justify-center">
                        <img
                            src="/images/icons/tools/generator%20tools/github%20readme%20generator/hpill.svg"
                            alt=""
                            className="w-full sm:w-8/12 aspect-square select-none pointer-events-none"
                            draggable="false"
                        />
                    </div>
                    <VerticalAds/>
                </div>
                <div className="hidden md:block">
                    <HorizontalAds/>
                </div>
                {alertVisible && (<ToastError title="Enter a Valid GitHub Username !"/>)}
            </div>)}
        </div>
    </>);
}
export var username = "";
