import React, {useEffect, useState} from 'react';
import FAQItem from "./FAQItem";
import endpoints from "../../../constants/endpoints";
import SquareAds from "../../../Ads/SquareAds";
import VerticalAds from "../../../Ads/VerticalAds";

function FAQ(toolName) {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(endpoints.faq, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => err);
    }, []);

    // console.log(data)

    return (
        <>
            {data
                ? (
                    <div className="md:flex">
                        <div className="m-4">
                            <VerticalAds/>
                        </div>
                        <div className="w-full mx-auto px-5 py-6">
                            <div className="flex flex-col items-center">
                                <h2 className="font-bold text-5xl mt-5 tracking-tight">
                                    FAQ
                                </h2>
                                <p className="text-neutral-500 text-xl mt-3">
                                    Frequenty asked questions
                                </p>
                            </div>
                            {data.tools.map((tool) =>
                                tool.title === toolName.tool ?
                                    tool.faq ?
                                        tool.faq.map((faq, key) =>
                                            <FAQItem key={key} answer={faq.answer}
                                                     question={faq.question}/>) :
                                        <div className="text-gray-400 m-3">
                                            <i className="fa-duotone fa-road-barrier px-2"></i> No Data Available
                                        </div>
                                    : ''
                            )}
                        </div>
                        <div className="m-4">
                            <VerticalAds/>
                        </div>
                    </div>
                ) : ''}
        </>);
}

export default FAQ;
