import React, {useEffect, useState} from 'react';
import endpoints from "../../constants/endpoints";
import HorizontalAds from "../../Ads/HorizontalAds";
import VerticalAds from "../../Ads/VerticalAds";
import {Helmet} from "react-helmet";

function Disclaimer() {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(endpoints.pages, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => err);
    }, []);

    return (
        <>
            <Helmet>
                <title>Disclaimer</title>
            </Helmet>
            {data
                ? (
                    <div>
                        <div>
                            <div className="mx-auto flex flex-col justify-center">
                                <div className="flex justify-center">
                                    {data.pages.map((page, key) => page.slug === '/disclaimer' ? (
                                            <center key={key} className="container mt-5 mb-24">
                                                <h1 className="text-center font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 ml-3 my-6">
                                                    {page.title}
                                                </h1>
                                                <div className="mx-8 md:mx-32 text-justify"
                                                     dangerouslySetInnerHTML={{__html: page.content}}></div>
                                            </center>
                                        ) : ('')
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </>
    );
}

export default Disclaimer;