import React, {useState} from "react";
import Meta from "../../../Components/meta/Meta";
import FormCP from "./components/form/FormCP";
import LoadUnload from "./components/form/LoadUnload";
import Preview from "./components/preview/Preview";
import DefaultResumeData from "./components/utility/DefaultResumeData";
import SocialMedia from "./components/form/SocialMedia";
import WorkExperience from "./components/form/WorkExperience";
import Skill from "./components/form/Skill";
import PersonalInformation from "./components/form/PersonalInformation";
import Summary from "./components/form/Summary";
import Projects from "./components/form/Projects";
import Education from "./components/form/Education";
import dynamic from "next/dynamic";
import HorizontalAds from "../../../../Ads/HorizontalAds";
import VerticalAds from "../../../../Ads/VerticalAds";
import SquareAds from "../../../../Ads/SquareAds";
import {Helmet} from "react-helmet";


export default function Builder(props) {
    // resume data
    const [resumeData, setResumeData] = useState(DefaultResumeData);
    // form hide/show
    const [formClose, setFormClose] = useState(false);

    // load backup resume data
    const handleLoad = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const resumeData = JSON.parse(event.target.result);
            setResumeData(resumeData);
        };
        reader.readAsText(file);
    };

    // download resume data
    const handleDownload = (data, filename, event) => {
        event.preventDefault();
        const jsonData = JSON.stringify(data);
        const blob = new Blob([jsonData], {type: "application/json"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    };

    // personal information
    const handleChange = (e) => {
        setResumeData({...resumeData, [e.target.name]: e.target.value});
        console.log(resumeData);
    };

    // profile picture
    const handleProfilePicture = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            setResumeData({...resumeData, profilePicture: event.target.result});
        };
        reader.readAsDataURL(file);
    };

    // education
    const handleEducation = (e, index) => {
        const newEducation = [...resumeData.education];
        newEducation[index][e.target.name] = e.target.value;
        setResumeData({...resumeData, education: newEducation});
    };

    const addEducation = () => {
        setResumeData({
            ...resumeData,
            education: [...resumeData.education, {school: "", degree: "", startYear: "", endYear: ""}],
        });
    };

    const removeEducation = (index) => {
        const newEducation = [...resumeData.education];
        newEducation[index] = newEducation[newEducation.length - 1];
        newEducation.pop();
        setResumeData({...resumeData, education: newEducation});
    };

    // work experience
    const handleworkExperience = (e, index) => {
        const newworkExperience = [...resumeData.workExperience];
        newworkExperience[index][e.target.name] = e.target.value;
        setResumeData({...resumeData, workExperience: newworkExperience});
    };

    const addworkExperience = () => {
        setResumeData({
            ...resumeData,
            workExperience: [
                ...resumeData.workExperience,
                {company: "", position: "", description: "", keyAchievements: "", startYear: "", endYear: ""},
            ],
        });
    };

    const removeworkExperience = (index) => {
        const newworkExperience = [...resumeData.workExperience];
        newworkExperience[index] = newworkExperience[newworkExperience.length - 1];
        newworkExperience.pop();
        setResumeData({...resumeData, workExperience: newworkExperience});
    };

    // projects
    const handleProjects = (e, index) => {
        const newProjects = [...resumeData.projects];
        newProjects[index][e.target.name] = e.target.value;
        setResumeData({...resumeData, projects: newProjects});
        console.log(resumeData.projects)
    };

    const addProjects = () => {
        setResumeData({
            ...resumeData,
            projects: [
                ...resumeData.projects,
                {name: "", description: "", keyAchievements: "", link: "", startYear: "", endYear: ""},
            ],
        });
    };

    const removeProjects = (index) => {
        const newProjects = [...resumeData.projects];
        newProjects[index] = newProjects[newProjects.length - 1];
        newProjects.pop();
        setResumeData({...resumeData, projects: newProjects});
    };

    // social media
    const handleSocialMedia = (e, index) => {
        const newSocialMedia = [...resumeData.socialMedia];
        newSocialMedia[index][e.target.name] = e.target.value.replace("https://", "");
        setResumeData({...resumeData, socialMedia: newSocialMedia});
    };

    const addSocialMedia = () => {
        setResumeData({
            ...resumeData,
            socialMedia: [...resumeData.socialMedia, {socialMedia: "", link: ""}],
        });
    };

    const removeSocialMedia = (index) => {
        const newSocialMedia = [...resumeData.socialMedia];
        newSocialMedia[index] = newSocialMedia[newSocialMedia.length - 1];
        newSocialMedia.pop();
        setResumeData({...resumeData, socialMedia: newSocialMedia});
    };

    // technical skills
    const handleTechnicalSkills = (e, index) => {
        const newTechnicalSkills = [...resumeData.technicalSkills];
        newTechnicalSkills[index] = e.target.value;
        setResumeData({...resumeData, technicalSkills: newTechnicalSkills});
    };

    const addTechnicalSkill = () => {
        setResumeData({...resumeData, technicalSkills: [...resumeData.technicalSkills, ""]});
    };

    const removeTechnicalSkill = (index) => {
        const newTechnicalSkills = [...resumeData.technicalSkills];
        newTechnicalSkills[index] = newTechnicalSkills[newTechnicalSkills.length - 1];
        newTechnicalSkills.pop();
        setResumeData({...resumeData, technicalSkills: newTechnicalSkills});
    };

    // soft skills
    const handleSoftSkills = (e, index) => {
        const newSoftSkills = [...resumeData.softSkills];
        newSoftSkills[index] = e.target.value;
        setResumeData({...resumeData, softSkills: newSoftSkills});
    };

    const addSoftSkill = () => {
        setResumeData({...resumeData, softSkills: [...resumeData.softSkills, ""]});
    };

    const removeSoftSkill = (index) => {
        const newSoftSkills = [...resumeData.softSkills];
        newSoftSkills[index] = newSoftSkills[newSoftSkills.length - 1];
        newSoftSkills.pop();
        setResumeData({...resumeData, softSkills: newSoftSkills});
    };

    // languages
    const handleLanguages = (e, index) => {
        const newLanguages = [...resumeData.languages];
        newLanguages[index] = e.target.value;
        setResumeData({...resumeData, languages: newLanguages});
    };

    const addLanguage = () => {
        setResumeData({...resumeData, languages: [...resumeData.languages, ""]});
    };

    const removeLanguage = (index) => {
        const newLanguages = [...resumeData.languages];
        newLanguages[index] = newLanguages[newLanguages.length - 1];
        newLanguages.pop();
        setResumeData({...resumeData, languages: newLanguages});
    };

    // additional skills
    const handleAdditionalSkills = (e, index) => {
        const newAdditionalSkills = [...resumeData.additionalSkills];
        newAdditionalSkills[index] = e.target.value;
        setResumeData({...resumeData, additionalSkills: newAdditionalSkills});
    };

    const addAdditionalSkill = () => {
        setResumeData({...resumeData, additionalSkills: [...resumeData.additionalSkills, ""]});
    };

    const removeAdditionalSkill = (index) => {
        const newAdditionalSkills = [...resumeData.additionalSkills];
        newAdditionalSkills[index] = newAdditionalSkills[newAdditionalSkills.length - 1];
        newAdditionalSkills.pop();
        setResumeData({...resumeData, additionalSkills: newAdditionalSkills});
    };

    // ceritifications
    const handleCertifications = (e, index) => {
        const newCertifications = [...resumeData.certifications];
        newCertifications[index] = e.target.value;
        setResumeData({...resumeData, certifications: newCertifications});
    };

    const addCertification = () => {
        setResumeData({...resumeData, certifications: [...resumeData.certifications, ""]});
    };

    const removeCertification = (index) => {
        const newCertifications = [...resumeData.certifications];
        newCertifications[index] = newCertifications[newCertifications.length - 1];
        newCertifications.pop();
        setResumeData({...resumeData, certifications: newCertifications});
    };


    return (
        <>
            <Helmet>
                <title>Resume Builder</title>
                <meta
                    name="descriptions"
                    content="Resume is a cutting-edge resume builder that helps job seekers create a professional, ATS-friendly resume in minutes. Our platform uses the latest technology to analyze and optimize your resume for maximum visibility and success with applicant tracking systems. Say goodbye to frustration and wasted time spent on manual resume formatting. Create your winning resume with ATSResume today and get noticed by employers."
                />
                <meta
                    name="keywords"
                    content="User friendly, Resume optimization, Keyword-rich resume, Applicant Tracking System, ATS resume builder, ATS resume templates, ATS-compliant resume, ATS-optimized CV, ATS-friendly format, ATS resume tips, Resume writing services, Career guidance, Job search in India, Resume tips for India, Professional resume builder, Cover letter writing, Interview preparation, Job interview tips, Career growth, Online job applications, resume builder, free resume builder, resume ats, best free resume builder, resume creator, resume cv, resume design, resume editor, resume maker"/>
            </Helmet>
            <div className="md:hidden md:m-4 exclude-print">
                <HorizontalAds/>
            </div>
            <div className="md:flex items-start">
                <div className="hidden md:flex flex-col items-center justify-center m-4 exclude-print">
                    <div className="my-3">
                        <SquareAds/>
                    </div>
                    <VerticalAds/>
                </div>
                <div>
                    <div className="flex flex-col gap-4 md:flex-row justify-evenly max-w-7xl md:mx-auto exclude-print">
                        {!formClose && (
                            <form
                                className="p-6 bg-gradient-to-r from-blue-500 to-blue-800 exclude-print md:max-w-[40%] md:h-screen md:overflow-y-scroll">
                                <LoadUnload handleLoad={handleLoad} handleDownload={handleDownload}
                                            resumeData={resumeData}/>
                                <PersonalInformation resumeData={resumeData} handleChange={handleChange}
                                                     handleProfilePicture={handleProfilePicture}/>
                                <SocialMedia resumeData={resumeData} handleSocialMedia={handleSocialMedia}
                                             addSocialMedia={addSocialMedia} removeSocialMedia={removeSocialMedia}/>
                                <Summary resumeData={resumeData} handleChange={handleChange}/>
                                <Education resumeData={resumeData} handleEducation={handleEducation}
                                           addEducation={addEducation}
                                           removeEducation={removeEducation}/>
                                <WorkExperience resumeData={resumeData} handleWorkExperience={handleworkExperience}
                                                addWorkExperience={addworkExperience}
                                                removeWorkExperience={removeworkExperience}/>
                                <Projects resumeData={resumeData} handleProjects={handleProjects}
                                          addProjects={addProjects}
                                          removeProjects={removeProjects}/>
                                <Skill resumeData={resumeData} skillType="technicalSkills" title="Technical Skills"
                                       placeholder="Skill" handleSkills={handleTechnicalSkills}
                                       addSkill={addTechnicalSkill}
                                       removeSkill={removeTechnicalSkill}/>
                                <Skill resumeData={resumeData} skillType="softSkills" title="Soft Skills"
                                       placeholder="Skill"
                                       handleSkills={handleSoftSkills} addSkill={addSoftSkill}
                                       removeSkill={removeSoftSkill}/>
                                <Skill resumeData={resumeData} skillType="languages" title="Languages"
                                       placeholder="Language"
                                       handleSkills={handleLanguages} addSkill={addLanguage}
                                       removeSkill={removeLanguage}/>
                                <Skill resumeData={resumeData} skillType="additionalSkills" title="Additional Skills"
                                       placeholder="Skill" handleSkills={handleAdditionalSkills}
                                       addSkill={addAdditionalSkill}
                                       removeSkill={removeAdditionalSkill}/>
                                <Skill resumeData={resumeData} skillType="certifications" title="Certifications"
                                       placeholder="Certification" handleSkills={handleCertifications}
                                       addSkill={addCertification} removeSkill={removeCertification}/>
                            </form>
                        )}
                        <Preview resumeData={resumeData}/>
                    </div>
                    <div className="md:m-4 exclude-print">
                        <HorizontalAds/>
                    </div>
                </div>

                <div className="hidden md:flex flex-col items-center justify-center m-4 exclude-print">
                    <div className="my-3">
                        <SquareAds/>
                    </div>
                    <VerticalAds/>
                </div>
            </div>
            <FormCP formClose={formClose} setFormClose={setFormClose}/>
            <Print/>
        </>
    )
};

// server side rendering false
const Print = dynamic(() => import("./components/utility/WinPrint"), {
    ssr: false,
});