import React, {Component} from 'react';

class FAQItem extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="grid divide-y divide-neutral-200 text-start max-w-xl mx-auto mt-4">
                <div className="py-5">
                    <details className="group">
                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                            <span>{this.props.question}</span>
                            <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor"
                     strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path
                    d="M6 9l6 6 6-6"></path>
</svg>
              </span>
                        </summary>
                        <p className="text-neutral-600 text-justify mt-3 group-open:animate-fadeIn">
                            {this.props.answer}
                        </p>
                    </details>
                </div>
            </div>)
    }
}

export default FAQItem;
