import React, {useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as XLSX from 'xlsx';
import HorizontalAds from "../../../../Ads/HorizontalAds";
import SquareAds from "../../../../Ads/SquareAds";
import FAQ from "../../Components/FAQ";
import {Helmet} from "react-helmet";

function EmailExtractor(props) {
    const [copy, setCopy] = useState(false)

    const [currentText, setCurrentText] = useState('');

    const [emails, setEmails] = useState([]);


    const downloadExcel = (emails) => {
        const mail = emails.map(email => ({'Emails': email}));
        const worksheet = XLSX.utils.json_to_sheet(mail);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Email List.xlsx");
    };


    return (
        <>
            <Helmet>
                <title>Email Extractor</title>
                <meta
                    name="descriptions"
                    content="Find and extract email addresses quickly and easily with an email extractor tool. Whether you need to build a mailing list, connect with potential clients or reach out to your existing contacts, an email extractor can help you gather the email addresses you need in no time. Discover the top email extractor tools available, and learn how you can streamline your email outreach with ease. Don't waste time manually searching for email addresses - let an email extractor do the work for you!"
                />
                <meta
                    name="keywords"
                    content="Email extractor, Email harvesting, Email finder, Email scraper, Email collector, Email search, Email grabber, Email crawler, Email search engine, Email address search, Email lookup, Email list builder, Email marketing, Email outreach, Email campaigns, Email contacts, Email leads, Email prospecting, Email validation, Email verification, Email management, Bulk email extraction"/>
            </Helmet>
            <div className="flex lg:flex-row flex-col items-center">
                <div className="inherit">
                    <SquareAds/>
                </div>
                <div className="flex flex-col justify-center mx-auto items-center text-light text-start w-full">
                    <h1 className="text-center mt-8 font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 ml-3 my-6">
                        Email Extractor
                    </h1>
                    <h1 className="mb-4 text-3xl font-extrabold md:text-4xl text-transparent bg-clip-text bg-gradient-to-r to-gray-900 from-gray-400">Guidelines</h1>
                    <div className="w-full">
                        <div className="flex">
                            <div
                                className="w-full text-justify bg-white shadow-lg rounded-lg p-4 mx-1 my-3">
                                <strong className="italic text-xl text-gray-300">01</strong>
                                <p>Copy the text or document you want to extract email addresses from.</p>
                            </div>
                            <div
                                className="w-full text-justify bg-white shadow-lg rounded-lg  p-4 mx-1 my-3">
                                <strong className="italic text-xl text-gray-300">02</strong>
                                <p>Paste the text or document in the source section below.</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div
                                className="w-full text-justify bg-white shadow-lg rounded-lg  p-4 mx-1 my-3">
                                <strong className="italic text-xl text-gray-300">03</strong>
                                <p>Click on the "Extract button" to start the extraction process.</p>
                            </div>
                            <div
                                className="w-full text-justify bg-white shadow-lg rounded-lg  p-4 mx-1 my-3">
                                <strong className="italic text-xl text-gray-300">04</strong>
                                <p>Now you can download or copy the results from the output section.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inherit">
                    <SquareAds/>
                </div>
            </div>
            <div className="flex flex-col md:flex-row m-6 items-center">
                <div className="col w-full sm:w-1/2">
                    <div className="box border rounded flex flex-col shadow bg-white">
                        <div className="bg-gray-100  bg-grey-lighter px-3 py-2 border-b"><h3
                            className="text-sm text-grey-darker font-medium">Source</h3></div>
                        <textarea className="text-grey-darkest flex-1 p-2 m-1 bg-transparent outline-0"
                                  placeholder="Paste the text or document in here"
                                  rows={14}
                                  value={currentText}
                                  onChange={event => setCurrentText(event.target.value)}
                        />
                    </div>
                </div>

                <div className="col mt-8 sm:ml-8 sm:mt-0">
                    <button
                        type="button"
                        className="transition h-fit px-4 duration-1000 text-white px-8 py-1 my-2 rounded bg-gradient-to-tl from-blue-700 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-700 rounded-lg"
                        onClick={() => {
                            const pattern = /[\w\_\.\-]+@[^\s\.]+(\.\w+){1,3}/g;
                            const emails = [];
                            currentText.replace(pattern, w => {
                                emails.push(w);
                                return w;
                            });
                            setCopy(false);
                            setEmails(emails);
                        }}

                    >
                        Extract
                    </button>
                </div>


                <div className="col mt-8 w-full sm:ml-8 sm:mt-0 sm:w-1/2">
                    <div className="box border rounded flex flex-col shadow bg-white  h-96">
                        <div className="flex bg-gray-100 px-3 py-2 border-b justify-between">
                            <div></div>
                            <h3 className="text-sm text-grey-darker font-medium">Output</h3>
                            <div>
                                <CopyToClipboard text={emails}
                                                 onCopy={() => setCopy(true)}>
                                    <button className={`${copy ? 'copied' : 'copy'}`}>
                                        {copy ? <i className="fa-duotone fa-clipboard"></i> :
                                            <i className="fa-duotone text-gray-500 fa-clipboard"></i>}
                                    </button>
                                </CopyToClipboard>
                                <label htmlFor="download-modal" className="hover:cursor-pointer">
                                <span className="text-gray-400 hover:text-gray-500">
                                <i className="fa-solid fa-download pl-3"></i> csv</span>
                                </label>
                            </div>
                        </div>
                        <div
                            className="text-grey-darkest flex-1 text-start overflow-auto lowercase p-2 m-1 bg-transparent">
                            {emails.map((email, key) => {
                                return (
                                    <p key={key}>
                                        <a href={"mailto:" + email}>
                                            {email}
                                        </a>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-4">
                <HorizontalAds/>
            </div>
            <div>
                <FAQ tool='Email Extractor'/>
            </div>
            <input type="checkbox" id="download-modal" className="modal-toggle"/>
            <label htmlFor="download-modal" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="flex justify-center">
                        <SquareAds/>
                    </div>
                    <button
                        onClick={() => downloadExcel(emails)}
                        type="button"
                        className="transition h-fit px-4 duration-1000 text-white px-8 py-1 my-2 rounded bg-gradient-to-tl from-blue-700 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-700 rounded-lg">
                        Download
                    </button>
                </label>
            </label>
        </>
    );
}

export default EmailExtractor;