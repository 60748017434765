import FormButton from "./FormButton"

const Projects = ({resumeData, handleProjects, addProjects, removeProjects}) => {
    return (
        <div className="flex flex-col gap-2 my-4">
            <h2 className="text-white">Projects</h2>
            {resumeData.projects.map((project, index) => (
                <div key={index} className="grid gap-2">
                    <input
                        type="text"
                        placeholder="Project Name"
                        name="name"
                        className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                        value={project.name}
                        onChange={(e) => handleProjects(e, index)}/>
                    <input
                        type="text"
                        placeholder="Link"
                        name="link"
                        className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                        value={project.link}
                        onChange={(e) => handleProjects(e, index)}/>
                    <textarea
                        type="text"
                        placeholder="Description"
                        name="description"
                        className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400 h-32"
                        value={project.description}
                        maxLength="250"
                        onChange={(e) => handleProjects(e, index)}/>
                    <textarea
                        type="text"
                        placeholder="Key Achievements"
                        name="keyAchievements"
                        className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400 h-40"
                        value={project.keyAchievements}
                        onChange={(e) => handleProjects(e, index)}/>
                    <div className="flex flex-wrap gap-2">
                        <input
                            type="date"
                            placeholder="Start Year"
                            name="startYear"
                            className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                            value={project.startYear}
                            onChange={(e) => handleProjects(e, index)}/>
                        <input
                            type="date"
                            placeholder="End Year"
                            name="endYear"
                            className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                            value={project.endYear}
                            onChange={(e) => handleProjects(e, index)}/>
                    </div>
                </div>
            ))}
            <FormButton size={resumeData.projects.length} add={addProjects} remove={removeProjects}/>
        </div>
    )
}

export default Projects;