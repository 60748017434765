import './App.css';
import Main from "./Main/Main";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ImageCompressor from "./Main/Tools/ImageCompressor/ImageCompressor"
import Nav from "./Main/Nav";
import Footer from "./Main/Footer";
import AboutUs from "./Main/Pages/AboutUs";
import TermsConditions from "./Main/Pages/TermsConditions";
import PrivacyPolicy from "./Main/Pages/PrivacyPolicy";
import Disclaimer from "./Main/Pages/Disclaimer";
import ContactUs from "./Main/Pages/ContactUs";
import EmailExtractor from "./Main/Tools/Text Tools/EmailExtractor/EmailExtractor";
import PasswordGenerator from "./Main/Tools/Generator Tools/PasswordGenerator/PasswordGenerator";
import TextEditor from "./Main/Tools/Text Tools/TextEditor/TextEditor";
import ScrollToTop from "./Main/Components/ScrollToTop";
import './styles/globals.css'
import './styles/animation.css'
import './styles/scrollbar.css'
import './styles/markdown.css'
import './styles/toastvg.css'
import HomePage from "./Main/Tools/Generator Tools/GithubReadMEGenerator/components/slides/HomePage";
import Builder from "./Main/Tools/Generator Tools/ResumeBuilder/Builder";
import {Helmet} from "react-helmet";
import React from "react";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Helmet>
                    <title>Techie LASH Tools</title>
                    <meta
                        name="descriptions"
                        content="Welcome to Techie LASH, the website dedicated to making your life easier with free online tools."
                    />
                    <meta
                        name="keywords"
                        content="Free online tech tools, Tech utilities, Online software for tech, Web-based tech tools, Internet tech tools, Free online tech software, Digital tech tools, Online tech solutions, Cloud-based tech tools, Browser-based tech tools, Online tech services, Free web tech tools, Online tech applications, Tech programs, Free online tech utilities, Tech gadgets, Online tech devices."/>
                </Helmet>
                <div className="bg-gradient-to-br from-blue-100 to-blue-200 min-h-screen">
                    <Nav/>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<Main/>}/>
                            <Route path="/image-compressor-online-tool" element={<ImageCompressor/>}/>
                            <Route path="text/email-extractor-online-tool" element={<EmailExtractor/>}/>
                            <Route path="text/text-editor-online-tool" element={<TextEditor/>}/>
                            <Route path="generator/random-password-generator-online-tool"
                                   element={<PasswordGenerator/>}/>
                            <Route path="generator/github-readme-generator"
                                   element={<HomePage/>}/>
                            <Route path="generator/cv-generator"
                                   element={<Builder/>}/>

                            <Route path="/about-us" element={<AboutUs/>}/>
                            <Route path="/terms-conditions" element={<TermsConditions/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/disclaimer" element={<Disclaimer/>}/>
                            <Route path="/contact-us" element={<ContactUs/>}/>
                        </Routes>
                    </ScrollToTop>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>);
}

export default App;
