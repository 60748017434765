import FormButton from "./FormButton"

const SocialMedia = ({resumeData, handleSocialMedia, addSocialMedia, removeSocialMedia}) => {
    return (
        <div className="flex flex-col gap-2 my-4">
            <h2 className="text-white">Social Media</h2>
            {resumeData.socialMedia.map((socialMedia, index) => (
                <div key={index} className="grid grid-cols-2 gap-2">
                    <input
                        type="text"
                        placeholder="Social Media"
                        name="socialMedia"
                        className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                        value={socialMedia.socialMedia}
                        onChange={(e) => handleSocialMedia(e, index)}/>
                    <input
                        type="text"
                        placeholder="Link"
                        name="link"
                        className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                        value={socialMedia.link}
                        onChange={(e) => handleSocialMedia(e, index)}/>
                </div>
            ))}
            <FormButton size={resumeData.socialMedia.length} add={addSocialMedia} remove={removeSocialMedia}/>
        </div>
    )
};


export default SocialMedia;