import React from 'react';
import logo from "../images/logo/logo.png";

function Nav() {
    return (
        <>
            <div className="exclude-print">
                <div className="flex flex-col md:flex-row justify-center items-center mx-auto my-8 ">
                    <a href="/">
                        <div className="flex justify-center mx-4">
                            <img src={logo} alt="" width="130" height="30"/>
                            <h1 className="text-center font-extrabold text-transparent text-xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 ml-3">Tools</h1>
                        </div>
                    </a>
                    <a href="/about-us">
                        <div className="flex justify-center px-3 md:border-l  md:mt-1 mt-3 mb-1">
                            About Us
                        </div>
                    </a>
                    <a href="/terms-conditions">
                        <div className="flex justify-center px-3 md:border-l my-1">
                            Terms & Conditions
                        </div>
                    </a>
                    <a href="/privacy-policy">
                        <div className="flex justify-center px-3 md:border-l my-1">
                            Privacy Policy
                        </div>
                    </a>
                    <a href="/disclaimer">
                        <div className="flex justify-center px-3 md:border-l my-1">
                            Disclaimer
                        </div>
                    </a>
                    <a href="/contact-us">
                        <div className="flex justify-center px-3 md:border-l my-1 md:border-r">
                            Contact Us
                        </div>
                    </a>
                    <a href="https://www.techielash.me">
                        <div className="flex justify-center px-3 md:border-l my-1 md:border-r">
                            Blog
                        </div>
                    </a>
                </div>
                <div className="pb-6 flex justify-center items-center">&copy; tools.techielash.me. Made
                    with ❤️ by <a
                        href="https://lashendev.tk/" className="flex items-center p-1">LASH <img
                        src="https://lashendev.tk/images/logo.png" alt="" className="p-2" width="40" height="40"/></a>
                </div>
            </div>
        </>
    );
}

export default Nav;