import React from "react";
import imageCompression from "browser-image-compression";
import 'animate.css';
import SquareAds from "../../../Ads/SquareAds";
import VerticalAds from "../../../Ads/VerticalAds";
import HorizontalAds from "../../../Ads/HorizontalAds";
import FAQ from "../Components/FAQ";
import {Helmet} from "react-helmet";

export default class imageCompressor extends React.Component {
    constructor() {
        super();
        this.state = {
            compressedLink: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAQlBMVEX///+hoaGenp6ampr39/fHx8fOzs7j4+P8/Pyvr6/d3d3FxcX29va6urqYmJjs7OzU1NSlpaW1tbWtra3n5+e/v78TS0zBAAACkUlEQVR4nO3b63KCMBCGYUwUUVEO6v3fagWVY4LYZMbZnff51xaZ5jON7CZNEgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQb5tvI8qzX4/nH84XG5Upfj2ir2V2E5fZ/XpIX9saMnhkYLIkiyRJjdgMoiEDMmiQgfwM8rSu77ew2wnPoLTmwdZBs0J2BuXrYckcQm4nOoP+WcmWAbcTnUHZPy9eA24nOoN7n0HI54ToDM5k8PjluwyqgNuJzqDoaugPg8gWZ4noDAYLwuIg75fLeeHHsjNIzrZJwWwW+0DNsmEWPjiEZ5AcD8ZUu8VZ8HyQMifvBdIz+PS33i8adu+7Qn4Gn1Tdupl7rlCfQb9seosK7RkcBy1o30iVZ5CPOtDW3WhQnsF13IV3v0p3BqfJRoSpXVepzmA/24+yqeMyzRm4tqOs44lSUwa3yfgOri25av5CPRnklR33VlPnrqSZV09qMsiqSWV082xOz1uPajJ49pTM/f115k6guWa6JGjJ4N1lt8fXN2rv/vysjFaSQdFXBc/KKF04ptFPliclGVR9Bu27XCyeVOkmy5OODAZN9rYyyip/AIPJ8qIig+PoXbf7YdPdncFoSdCQQT4ZceV+MhiFMBy0hgyu0yGvOLI17KwpyGBaHK5jtt0N5GcwLw7XZdB31sRn8O+ziqYro8Vn4CwOV+k6a9Iz+PwRsKC7h+gMfMXhKu/OmuwM/MXhKq8yWnYG/uJw5Uxoy2jRGZTBZ/jboxuSM1guDtdNhKazJjiDbNMe0AxzKUVnkO+jEJxBxNtJzWCTxlNLzSB8KehJ/H+mJGYAjaDjzj9SnHZRuXZiAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAECXP1XDHv7U4SNFAAAAAElFTkSuQmCC",
            originalImage: "",
            originalLink: "",
            originalSize: "",
            compressedSize: "",
            clicked: false,
            uploadImage: false
        };
    }

    handle = e => {
        this.setState({
            compressedLink: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAQlBMVEX///+hoaGenp6ampr39/fHx8fOzs7j4+P8/Pyvr6/d3d3FxcX29va6urqYmJjs7OzU1NSlpaW1tbWtra3n5+e/v78TS0zBAAACkUlEQVR4nO3b63KCMBCGYUwUUVEO6v3fagWVY4LYZMbZnff51xaZ5jON7CZNEgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQb5tvI8qzX4/nH84XG5Upfj2ir2V2E5fZ/XpIX9saMnhkYLIkiyRJjdgMoiEDMmiQgfwM8rSu77ew2wnPoLTmwdZBs0J2BuXrYckcQm4nOoP+WcmWAbcTnUHZPy9eA24nOoN7n0HI54ToDM5k8PjluwyqgNuJzqDoaugPg8gWZ4noDAYLwuIg75fLeeHHsjNIzrZJwWwW+0DNsmEWPjiEZ5AcD8ZUu8VZ8HyQMifvBdIz+PS33i8adu+7Qn4Gn1Tdupl7rlCfQb9seosK7RkcBy1o30iVZ5CPOtDW3WhQnsF13IV3v0p3BqfJRoSpXVepzmA/24+yqeMyzRm4tqOs44lSUwa3yfgOri25av5CPRnklR33VlPnrqSZV09qMsiqSWV082xOz1uPajJ49pTM/f115k6guWa6JGjJ4N1lt8fXN2rv/vysjFaSQdFXBc/KKF04ptFPliclGVR9Bu27XCyeVOkmy5OODAZN9rYyyip/AIPJ8qIig+PoXbf7YdPdncFoSdCQQT4ZceV+MhiFMBy0hgyu0yGvOLI17KwpyGBaHK5jtt0N5GcwLw7XZdB31sRn8O+ziqYro8Vn4CwOV+k6a9Iz+PwRsKC7h+gMfMXhKu/OmuwM/MXhKq8yWnYG/uJw5Uxoy2jRGZTBZ/jboxuSM1guDtdNhKazJjiDbNMe0AxzKUVnkO+jEJxBxNtJzWCTxlNLzSB8KehJ/H+mJGYAjaDjzj9SnHZRuXZiAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAECXP1XDHv7U4SNFAAAAAElFTkSuQmCC',
            clicked: false,

        });

        const imageFile = e.target.files[0];

        this.setState({
            originalLink: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            originalSize: (imageFile.size / (1024 * 1024)).toFixed(2),
            uploadImage: true
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    click = e => {
        e.preventDefault();

        const options = {
            maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true
        };

        if (options.maxSizeMB >= (this.state.originalImage.size / (1024 * 1024))) {
            alert("Image is too small, can't be Compressed!");
            return 0;
        }

        let output;
        imageCompression(this.state.originalImage, options).then(x => {
            output = x;

            const downloadLink = URL.createObjectURL(output);

            this.setState({
                compressedLink: downloadLink, compressedSize: (output.size / (1024 * 1024)).toFixed(2),
            });
        });

        this.setState({clicked: true});
        return 1;
    };


    render() {
        return (
            <>
                <Helmet>
                    <title>Image Compressor</title>
                    <meta
                        name="descriptions"
                        content="Compress your image files without sacrificing quality with an image compressor. Optimize your website, social media, and other digital platforms for faster loading times while saving storage space on your devices or in the cloud. Discover the benefits of using an image compressor and find the best tools available to help you compress your images quickly and easily. Boost your online performance today with the power of an image compressor."
                    />
                    <meta name="keywords" content="Image compression, File size reduction, Image optimization, Website optimization, Faster loading times, Storage space saving, Lossless compression, Web optimization, Online image compressor, Digital image compression, Image file compression, Photo compressor, Picture compressor, JPEG compression, PNG compression, Compression ratio, Image quality, Compression algorithm"/>
                </Helmet>
                <div className="lg:flex">
                    {/*<div className="m-4 hidden xl:block ">*/}
                    {/*    <VerticalAds/>*/}
                    {/*</div>*/}
                    <div className="m-2">
                        <div className="text-light text-center">
                            <h1 className="text-center mt-8 font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-700 font-bold text-3xl mt-2 ml-3 my-6">
                                Image Compressor
                            </h1>
                            <h1 className="mb-4 text-2xl font-extrabold md:text-3xl text-transparent bg-clip-text bg-gradient-to-r to-gray-900 from-gray-400">Three
                                Simple Steps</h1>
                            <div className="hidden md:block">
                                <HorizontalAds/>
                            </div>
                            <div className="flex flex-col xl:flex-row items-center w-full">
                                <div
                                    className="flex flex-col lg:w-1/3 items-center text-justify bg-white shadow-lg rounded-lg p-8 m-4  ">
                                    <h3 className="text-xl py-3 font-bold">1. Upload Image</h3>
                                    <p>First, you need to select the image you want to compress from your device or
                                        cloud
                                        storage
                                        and
                                        upload it to the online tool.<span
                                            className="text-red-600 font-bold">* Important</span> <span
                                            className="underline">Image file
                            formats
                            such as JPEG, PNG, and GIF.</span></p>
                                </div>
                                <div
                                    className="flex flex-col lg:w-1/3 items-center text-justify bg-white shadow-lg rounded-lg  p-8 m-4">
                                    <h3 className="text-xl py-3 font-bold">2. Click on Compress</h3>
                                    <p> Once you have uploaded the image, you need to click on the
                                        "Compress" button on the tool's interface. The tool will then analyze the
                                        image and compress it using its compression algorithm.</p>
                                </div>
                                <div
                                    className="flex flex-col lg:w-1/3 items-center text-justify bg-white shadow-lg rounded-lg  p-8 m-4">
                                    <h3 className="text-xl py-3 font-bold">3. Download Compressed Image</h3>
                                    <p>After the compression process is complete, the tool will
                                        display the compressed image alongside its original file size and the new file
                                        size.
                                        You
                                        can download the compressed image to your device.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="flex flex-col xl:flex-row md:items-center xl:items-start justify-evenly mt-12 p-4">
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                {this.state.uploadImage ? (<div className="shadow-lg h-56 md:h-72 w-full md:w-[450px]">
                                    <img
                                        className="h-56 md:h-72 w-full md:w-[450px] object-cover"
                                        variant="top"
                                        src={this.state.originalLink}
                                    />
                                </div>) : (<div className="shadow-lg shadow-blue-600 h-56 md:h-72 w-full md:w-[450px]">
                                    <img
                                        className="h-56 md:h-72 w-full md:w-[450px] object-cover"
                                        variant="top"
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAQlBMVEX///+hoaGenp6ampr39/fHx8fOzs7j4+P8/Pyvr6/d3d3FxcX29va6urqYmJjs7OzU1NSlpaW1tbWtra3n5+e/v78TS0zBAAACkUlEQVR4nO3b63KCMBCGYUwUUVEO6v3fagWVY4LYZMbZnff51xaZ5jON7CZNEgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQb5tvI8qzX4/nH84XG5Upfj2ir2V2E5fZ/XpIX9saMnhkYLIkiyRJjdgMoiEDMmiQgfwM8rSu77ew2wnPoLTmwdZBs0J2BuXrYckcQm4nOoP+WcmWAbcTnUHZPy9eA24nOoN7n0HI54ToDM5k8PjluwyqgNuJzqDoaugPg8gWZ4noDAYLwuIg75fLeeHHsjNIzrZJwWwW+0DNsmEWPjiEZ5AcD8ZUu8VZ8HyQMifvBdIz+PS33i8adu+7Qn4Gn1Tdupl7rlCfQb9seosK7RkcBy1o30iVZ5CPOtDW3WhQnsF13IV3v0p3BqfJRoSpXVepzmA/24+yqeMyzRm4tqOs44lSUwa3yfgOri25av5CPRnklR33VlPnrqSZV09qMsiqSWV082xOz1uPajJ49pTM/f115k6guWa6JGjJ4N1lt8fXN2rv/vysjFaSQdFXBc/KKF04ptFPliclGVR9Bu27XCyeVOkmy5OODAZN9rYyyip/AIPJ8qIig+PoXbf7YdPdncFoSdCQQT4ZceV+MhiFMBy0hgyu0yGvOLI17KwpyGBaHK5jtt0N5GcwLw7XZdB31sRn8O+ziqYro8Vn4CwOV+k6a9Iz+PwRsKC7h+gMfMXhKu/OmuwM/MXhKq8yWnYG/uJw5Uxoy2jRGZTBZ/jboxuSM1guDtdNhKazJjiDbNMe0AxzKUVnkO+jEJxBxNtJzWCTxlNLzSB8KehJ/H+mJGYAjaDjzj9SnHZRuXZiAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAECXP1XDHv7U4SNFAAAAAElFTkSuQmCC"
                                    ></img>
                                </div>)}
                                <div className="flex justify-center">
                                    <input type="file"
                                           className="text-sm text-grey-500
                                        file:mr-5 file:my-4 file:py-2 file:px-6
                                        file:rounded-full file:border-0
                                        file:text-sm file:font-medium
                                        file:bg-blue-200 file:text-blue-700
                                        hover:file:cursor-pointer hover:file:bg-blue-500
                                        hover:file:text-white"
                                           type="file"
                                           accept="image/*"
                                           onChange={e => this.handle(e)}
                                    />
                                </div>
                            </div>
                            <div
                                className="flex md:w-96 justify-center items-center">
                                <br/>
                                {this.state.outputFileName ? (<div className="m-4">
                                        {!this.state.clicked ? (<button
                                            type="button"
                                            className="shadow-lg shadow-blue-600 bg-white text-blue-800 hover:bg-blue-700 hover:text-white font-bold py-2 px-4 rounded"
                                            onClick={e => this.click(e)}
                                        >
                                            Compress
                                        </button>) : (<div>
                                            <div className="m-4 md:w-96 animate__animated animate__fadeIn"
                                                 style={{fontFamily: 'Space Mono'}}>
                                                <h6 className="text-md font-bold">🎉️ Image Compressed Successfully</h6>
                                                <div className="text-start my-4 p-4 bg-blue-50 shadow-lg border-0">
                                                    <p className="text-lg">Summary <i
                                                        className="fa fa-circle text-sm animate-pulse text-green-500 px-2"></i>
                                                    </p>
                                                    <ul className="m-2">
                                                        <li><span
                                                            className="font-bold pr-2">File Name</span> {this.state.outputFileName}
                                                        </li>
                                                        <li><span
                                                            className="font-bold pr-2">Original Size</span> {this.state.originalSize} MB
                                                        </li>
                                                        <li className="animate__animated animate__fadeIn animate__delay-1s"><span
                                                            className="font-bold pr-2">Compressed Size</span> {this.state.compressedSize} MB
                                                        </li>
                                                    </ul>
                                                    <div
                                                        className="flex items-center justify-center mt-6 animate__animated animate__fadeIn animate__delay-1s">
                                                        <div className="radial-progress text-blue-700"
                                                             style={{"--value": ((this.state.originalSize - this.state.compressedSize) / this.state.originalSize * 100).toFixed(2)}}>{((this.state.originalSize - this.state.compressedSize) / this.state.originalSize * 100).toFixed(2)}%
                                                        </div>
                                                        <p className="px-2 text-blue-700">Compressed</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <a
                                                type="button"
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                                                href="/image-compressor-online-tool"
                                            >
                                                <i className="fa-solid fa-rotate-left"></i>
                                            </a>
                                        </div>)}
                                    </div>

                                ) : (<SquareAds/>)}
                            </div>

                            <div>
                                <div
                                    className={this.state.clicked ? 'shadow-lg shadow-blue-600 h-56 md:h-72 w-full md:w-[450px]' : 'shadow-lg h-56 md:h-72 w-full md:w-[450px] inline-block'}>
                                    <img className="inline h-56 md:h-72 w-full md:w-[450px] object-cover" variant="top"
                                         src={this.state.compressedLink}/>
                                </div>
                                {this.state.clicked ? (<div>
                                    <label htmlFor="download-modal"
                                           className="bg-gray-300 hover:cursor-pointer hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                                        <svg className="fill-current w-4 h-4 mr-2"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20">
                                            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
                                        </svg>
                                        <span>Download</span>
                                    </label>
                                </div>) : (<></>)}
                            </div>
                        </div>
                    </div>
                    {/*<div className="m-4 hidden xl:block ">*/}
                    {/*    <VerticalAds/>*/}
                    {/*</div>*/}
                </div>

                <div>
                    <FAQ tool='Image Compressor'/>
                </div>

                <input type="checkbox" id="download-modal" className="modal-toggle"/>
                <label htmlFor="download-modal" className="modal cursor-pointer">
                    <label className="modal-box relative" htmlFor="">
                        <div className="flex justify-center">
                            <SquareAds/>
                        </div>

                        <a href={this.state.compressedLink}
                           download={this.state.outputFileName}
                        >
                            <button
                                type="button"
                                className="transition h-fit px-4 duration-1000 text-white px-8 py-1 my-2 rounded bg-gradient-to-tl from-blue-700 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-700 rounded-lg">
                                Download
                            </button>
                        </a>
                    </label>
                </label>
            </>
        );
    }
}