import FormButton from "./FormButton"

const Skill = ({ resumeData, skillType, title, placeholder, handleSkills, addSkill, removeSkill }) => {
    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-white">{title}</h2>
        {resumeData[skillType].map((skill, index) => (
          <div key={index} className="f-col">
            <input
              type="text"
              placeholder={placeholder}
              name="skill"
              className="w-full peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
              value={skill}
              onChange={(e) => handleSkills(e, index, skillType)} />
          </div>
        ))}
        <FormButton size={resumeData[skillType].length} add={addSkill} remove={removeSkill} />
      </div>
    )
  }

export default Skill;