import React, {useEffect, useState} from 'react';
import Icon from "./Components/Icon";
import endpoints from "../constants/endpoints";
import HorizontalAds from "../Ads/HorizontalAds";
import VerticalAds from "../Ads/VerticalAds";


function Main() {
    const [data, setData] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        fetch(endpoints.tools, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => err);
    }, []);

    useEffect(() => {
        fetch(endpoints.categories, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => setCategories(res))
            .catch((err) => err);
    }, []);

    return (<>
            {data ? (<div className="md:flex p-4">
                <div>
                    <VerticalAds/>
                </div>
                <div className="mx-auto md:p-12 flex flex-col justify-center items-center ">
                    {categories ? categories.categories.map((category) =>
                        <div key={category.id}>
                            {/*<Link to={category.url}>*/}
                            <div className="flex flex-col mx-auto border-0 justify-start">
                            <span
                                className="px-4 text-start absolute text-xl m-3 bg-gradient-to-r from-gray-800 to-gray-500 text-gray-300 rounded-xl shadow-lg">{category.name}</span>
                                <div
                                    className="grid grid-cols-3 sm:grid-cols-4 xl:grid-cols-6 container border py-2 px-6 my-6 bg-gradient-to-r from-blue-500 to-blue-800 text-blue-300 rounded-xl shadow-lg	">
                                    {data.tools.map((tool, key) => (
                                        category.name === tool.category ?
                                            <div key={key}>
                                                <Icon key={tool.id} title={tool.title}
                                                      category={tool.category}
                                                      img={tool.img}
                                                      url={tool.url}/>
                                            </div> : ''))}
                                </div>
                            </div>
                            {/*</Link>*/}
                        </div>
                    ) : ''}
                    <div className="hidden md:block">
                        <HorizontalAds/>
                    </div>
                </div>
                <div>
                    <VerticalAds/>
                </div>
            </div>) : ''}
        </>
    )
}

export default Main;