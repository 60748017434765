import Icon from "../../../../../Components/Icon";

const TechSkills = ({title, skills}) => {
    return (skills.length > 0 && <div>
        <h2 className="w-full flex mb-1 border-b-2 border-gray-300">{title}</h2>
        <p className="text-left">
            {skills.map((skill, key) => <>
                <div key={key} className="m-1"><i
                    className="fa-duotone fa-file-certificate text-blue-500 pe-2"></i>{skill}</div>
            </>)}
        </p>
    </div>);
};

export default TechSkills;