import React, {useEffect} from 'react';

function VerticalAds() {

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
        }
    }, []);

    return (<div className="w-full md:w-[200px] h-[280px] md:h-[680px] bg-gray-100">
        <span className="text-gray-400"><i className="fa-light fa-rectangle-ad"></i></span>
        <script async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7685021931588373"
                crossOrigin="anonymous"></script>
        <ins className="adsbygoogle"
             style={{display: "block"}}
             data-ad-client="ca-pub-7685021931588373"
             data-ad-slot="2245044834"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
    </div>);
}

export default VerticalAds;