import React from "react";

export default function BackButton({onClick, to}) {
    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <div className="flex">
            <button
                className="flex left-0 m-2 opacity-80 hover:opacity-100 hover:font-bold transition-all ease-in-out outline-none transition-all ease-in-out"
                onClick={() => onClick() & topFunction()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"/>
                </svg>
                {to}
            </button>
        </div>
    );
}
