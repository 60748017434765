import FormButton from "./FormButton"

const PersonalInformation = ({resumeData, handleChange, handleProfilePicture}) => {
    return (<div className="flex flex-col gap-2 my-4">
        <h2 className="text-white">Personal Information</h2>
        <div className="grid grid-cols-2 gap-2">
            <input
                type="text"
                placeholder="Full Name"
                name="name"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={resumeData.name}
                onChange={handleChange}/>
            <input
                type="text"
                placeholder="Job Title"
                name="position"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={resumeData.position}
                onChange={handleChange}/>
            <input
                type="text"
                placeholder="Contact Information"
                name="contactInformation"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={resumeData.contactInformation}
                onChange={handleChange}
                minLength="10"
                maxLength="15"/>
            <input
                type="email"
                placeholder="Email"
                name="email"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={resumeData.email}
                onChange={handleChange}/>
            <input
                type="text"
                placeholder="Address"
                name="address"
                className="peer h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:shadow-lg focus:shadow-blue-400"
                value={resumeData.address}
                onChange={handleChange}/>
            <div className="flex w-full items-center justify-center bg-grey-lighter">
                <label
                    className="bg-blue-400 text-white font-medium text-sm py-2.5 px-6 rounded-3xl hover:bg-blue-500 hover:cursor-pointer text-center">
                    <span className="mt-2 text-base leading-normal"><i className="fa-duotone fa-user-vneck-hair pe-1"></i> Profile
                Pic</span>
                    <input type="file" name="profileImage" accept="image/*" className="hidden"
                           onChange={handleProfilePicture} placeholder="Profile Picture"/>
                </label>
            </div>
        </div>
    </div>)
};


export default PersonalInformation;