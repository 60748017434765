import React from "react";

export default function TextInputWithIcon({id, children, placeholder, value}) {
    return (<div className="flex w-full md:w-5/12 my-2">
            <input
                type="text"
                id={id}
                placeholder={placeholder}
                defaultValue={value}
                className="w-full h-16 p-4 border-b-2 bg-transparent outline-none border-green-300 placeholder-white focus:border-green-200 focus:z-10"
            />

            <label htmlFor={id} className="p-5">
                <span className="sr-only"> {id} </span>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="-0.5 0 20 16"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-8 h-8 text-white opacity-75"
                >
                    {children}
                </svg>
            </label>
        </div>);
}
